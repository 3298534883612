<template>
  <div :style="{ padding: '150px' }">
    <h1>DateTime Picker</h1>

    <DateTimePicker :point="point" />

    <b-button type="is-primary" @click="update">Update</b-button>
    <b-input v-model="input"></b-input>
  </div>
</template>

<script>
import DateTimePicker from '@/components/inputs/DateTimePicker'

import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    DateTimePicker
  },
  data,
  methods
}
</script>
